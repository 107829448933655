import { useCallback, useState, useMemo, useEffect, Fragment } from 'react'
import styled from 'styled-components'
import { NextLinkFromReactRouter } from 'components/NextLink'
import { Text, Flex, Box, Skeleton, ArrowBackIcon, ArrowForwardIcon } from '@pancakeswap/uikit'
import { formatAmount } from 'utils/formatInfoNumbers'
import { PoolData } from 'state/info/types'
import { ITEMS_PER_INFO_TABLE_PAGE } from 'config/constants/info'
import { DoubleCurrencyLogo } from 'views/Info/components/CurrencyLogo'
import { useTranslation } from 'contexts/Localization'
import { TableWrapper, PageButtons, Arrow, Break } from './shared'
import styles from './Tables.module.scss'
import Link from 'next/link'
/**
 *  Columns on different layouts
 *  5 = | # | Pool | TVL | Volume 24H | Volume 7D |
 *  4 = | # | Pool |     | Volume 24H | Volume 7D |
 *  3 = | # | Pool |     | Volume 24H |           |
 *  2 = |   | Pool |     | Volume 24H |           |
 */

const SORT_FIELD = {
	volumeUSD: 'volumeUSD',
	liquidityUSD: 'liquidityUSD',
	volumeUSDWeek: 'volumeUSDWeek',
	lpFees24h: 'lpFees24h',
	lpApr7d: 'lpApr7d',
}

const LoadingRow: React.FC = () => (
	<div className={styles.responsiveGrid}>
		<Skeleton />
		<Skeleton />
		<Skeleton />
		<Skeleton />
		<Skeleton />
		<Skeleton />
		<Skeleton />
	</div>
)

const TableLoader: React.FC = () => (
	<>
		<LoadingRow />
		<LoadingRow />
		<LoadingRow />
	</>
)

const DataRow = ({ poolData, index }: { poolData: PoolData; index: number }) => {
	return (
		<div className={styles.tab}>
			<div className={styles.text}>
				<h6>{index + 1}</h6>
			</div>
			<div>
				<Link href={`/info/pool/${poolData.address}`}>
					<a className={styles.smallRow}>
						<div className={styles.icon}>
							<DoubleCurrencyLogo address0={poolData.token0.address} address1={poolData.token1.address} size={28} />
						</div>
						<h5>{poolData.token0.symbol}/{poolData.token1.symbol}</h5>
					</a>
				</Link>
			</div>
			<div className={styles.text}>
				<h6>${formatAmount(poolData.volumeUSD)}</h6>
			</div>
			<div className={styles.text}>
				<h6>${formatAmount(poolData.volumeUSDWeek)}</h6>
			</div>
			<div className={styles.text}>
				<h6>${formatAmount(poolData.lpFees24h)}</h6>
			</div>
			<div className={styles.text}>
				<h6>{formatAmount(poolData.lpApr7d)}%</h6>
			</div>
			<div className={styles.text}>
				<h6>${formatAmount(poolData.liquidityUSD)}</h6>
			</div>
		</div>
	)
}

interface PoolTableProps {
	poolDatas: PoolData[]
	loading?: boolean // If true shows indication that SOME pools are loading, but the ones already fetched will be shown
}

const PoolTable: React.FC<PoolTableProps> = ({ poolDatas, loading }) => {
	// for sorting
	const [sortField, setSortField] = useState(SORT_FIELD.volumeUSD)
	const [sortDirection, setSortDirection] = useState<boolean>(true)
	const { t } = useTranslation()

	// pagination
	const [page, setPage] = useState(1)
	const [maxPage, setMaxPage] = useState(1)
	useEffect(() => {
		let extraPages = 1
		if (poolDatas.length % ITEMS_PER_INFO_TABLE_PAGE === 0) {
			extraPages = 0
		}
		setMaxPage(Math.floor(poolDatas.length / ITEMS_PER_INFO_TABLE_PAGE) + extraPages)
	}, [poolDatas])

	const sortedPools = useMemo(() => {
		return poolDatas
			? poolDatas
				.sort((a, b) => {
					if (a && b) {
						return a[sortField as keyof PoolData] > b[sortField as keyof PoolData]
							? (sortDirection ? -1 : 1) * 1
							: (sortDirection ? -1 : 1) * -1
					}
					return -1
				})
				.slice(ITEMS_PER_INFO_TABLE_PAGE * (page - 1), page * ITEMS_PER_INFO_TABLE_PAGE)
			: []
	}, [page, poolDatas, sortDirection, sortField])

	const handleSort = useCallback(
		(newField: string) => {
			setSortField(newField)
			setSortDirection(sortField !== newField ? true : !sortDirection)
		},
		[sortDirection, sortField],
	)

	const arrow = useCallback(
		(field: string) => {
			const directionArrow = !sortDirection ? '↑' : '↓'
			return sortField === field ? directionArrow : ''
		},
		[sortDirection, sortField],
	)

	return (
		<>
			<div className={styles.pools_wrapper}>
				<div className={styles.pools_container}>
					<div className={styles.pools_row}>
						<div className={styles.tab}>
							<div className={styles.text}>
								<h6>#</h6>
							</div>
							<div className={styles.text}>
								<h6>{t('Pool')}</h6>
							</div>
							<div className={styles.text}>
								<h6
									className={styles.clickableText}
									onClick={() => handleSort(SORT_FIELD.volumeUSD)}
								>
									{t('Volume 24H')} {arrow(SORT_FIELD.volumeUSD)}
								</h6>
							</div>
							<div className={styles.text}>
								<h6
									className={styles.clickableText}
									onClick={() => handleSort(SORT_FIELD.volumeUSDWeek)}
								>
									{t('Volume 7D')} {arrow(SORT_FIELD.volumeUSDWeek)}
								</h6>
							</div>
							<div className={styles.text}>
								<h6
									className={styles.clickableText}
									onClick={() => handleSort(SORT_FIELD.lpFees24h)}
								>
									{t('LP fees 24H')} {arrow(SORT_FIELD.lpFees24h)}
								</h6>
							</div>
							<div className={styles.text}>
								<h6
									className={styles.clickableText}
									onClick={() => handleSort(SORT_FIELD.lpApr7d)}
								>
									{t('LP reward APR')} {arrow(SORT_FIELD.lpApr7d)}
								</h6>
							</div>
							<div className={styles.text}>
								<h6
									className={styles.clickableText}
									onClick={() => handleSort(SORT_FIELD.liquidityUSD)}
								>
									{t('Liquidity')} {arrow(SORT_FIELD.liquidityUSD)}
								</h6>
							</div>
						</div>
					</div>

					{sortedPools.length > 0 ? (
						<>
							{sortedPools.map((poolData, i) => {
								if (poolData) {
									return (
										<div className={styles.pools_row} key={poolData.address}>
											<DataRow index={(page - 1) * ITEMS_PER_INFO_TABLE_PAGE + i} poolData={poolData} />
										</div>
									)
								}
								return null
							})}
							<PageButtons>
								<Arrow
									onClick={() => {
										setPage(page === 1 ? page : page - 1)
									}}
								>
									<ArrowBackIcon color={page === 1 ? 'textDisabled' : 'primary'} />
								</Arrow>

								<Text>{t('Page %page% of %maxPage%', { page, maxPage })}</Text>

								<Arrow
									onClick={() => {
										setPage(page === maxPage ? page : page + 1)
									}}
								>
									<ArrowForwardIcon color={page === maxPage ? 'textDisabled' : 'primary'} />
								</Arrow>
							</PageButtons>
						</>
					) : (
						<>
							<TableLoader />
							<Box />
						</>
					)}
				</div>
			</div>
		</>
	)
}

export default PoolTable
