import { useState } from 'react'

import { Button } from '@/shared'
import styles from './OverviewChartContainer.module.scss'
import ChartDetails from '../chartDetails/ChartDetails'
import { getTimeWindowChange, getTokenAddress } from '@/components/Swap/Chart2/utils'
import { PairDataTimeWindowEnum } from '@/state/swap/types'
import { useFetchPairPrices, useSingleTokenSwapInfo } from '@/state/swap/hooks'
import { useCurrency } from '@/hooks/Tokens'
import { useTranslation } from '@/contexts/Localization'
import dynamic from 'next/dynamic'
import { OVERVIEW_BLOCKS_CLIENT, OVERVIEW_INFO_CLIENT } from '@/config/constants/endpoints'
import { DES_TOKENS, USDT_TOKENS } from '@/config/constants'
import { CHAIN_ID } from '@/config/constants/networks'

const SwapLineChart = dynamic(() => import('@/components/Overview/chart/SwapLineChart'), {
  ssr: false,
})

const OverviewChartContainer = () => {
  const buttonList: { button: string; id: number; value: string }[] = [
    {
      id: 0,
      button: '1D',
      value: 'hours',
    },
    {
      id: 1,
      button: '7D',
      value: 'days',
    },
    {
      id: 2,
      button: '1M',
      value: 'month',
    },
    {
      id: 4,
      button: '1Y',
      value: 'year',
    },
  ]

  const inputCurrencyId = DES_TOKENS[CHAIN_ID] //"0xb38b3c34e4bb6144c1e5283af720E046Ee833a2a"; // DES
  const outputCurrencyId = USDT_TOKENS[CHAIN_ID] // "0x55d398326f99059fF775485246999027B3197955"; // USDT

  const token0Address = getTokenAddress(inputCurrencyId)
  const token1Address = getTokenAddress(outputCurrencyId)

  const inputCurrency = useCurrency(inputCurrencyId)
  const outputCurrency = useCurrency(outputCurrencyId)

  const currentSwapPrice = useSingleTokenSwapInfo(inputCurrencyId, inputCurrency, outputCurrencyId, outputCurrency)

  const [timeWindow, setTimeWindow] = useState<PairDataTimeWindowEnum>(0)
  const { pairPrices = [], pairId } = useFetchPairPrices({
    token0Address,
    token1Address,
    timeWindow,
    currentSwapPrice,
  })
  const [hoverValue, setHoverValue] = useState<number | undefined>()
  const [hoverDate, setHoverDate] = useState<string | undefined>()
  const valueToDisplay = hoverValue || pairPrices[pairPrices.length - 1]?.value
  const { changePercentage, changeValue } = getTimeWindowChange(pairPrices)
  const isChangePositive = changeValue >= 0
  const {
    t,
    currentLanguage: { locale },
  } = useTranslation()

  return (
    <div className={styles.overviewContainer}>
      <ChartDetails
        value={pairPrices?.length > 0 && valueToDisplay}
        inputSymbol={inputCurrency?.symbol}
        outputSymbol={outputCurrency?.symbol}
        isChangePositive={isChangePositive}
        changeValue={changeValue}
        changePercentage={changePercentage}
      />
      <SwapLineChart
        data={pairPrices}
        setHoverValue={setHoverValue}
        setHoverDate={setHoverDate}
        isChangePositive={isChangePositive}
        timeWindow={timeWindow}
        outputSymbol={outputCurrency?.symbol}
      />
      {/* <OverviewChart button={button} /> */}
      <div className={styles.overviewContainer_btnContainer}>
        {buttonList.map(({ button }, index) => {
          return (
            <Button
              type="transparent"
              key={index}
              className={`${styles.overviewContainer_btn} ${
                index === timeWindow ? styles.overviewContainer_btnActive : ''
              }`}
              onClick={() => setTimeWindow(index)}
            >
              {button}
            </Button>
          )
        })}
      </div>
    </div>
  )
}

export default OverviewChartContainer
