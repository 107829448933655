import { useState, useMemo, useCallback, useEffect, Fragment } from 'react'
import styled from 'styled-components'
import { Text, Flex, Box, Skeleton, useMatchBreakpoints, ArrowBackIcon, ArrowForwardIcon } from '@pancakeswap/uikit'
import { TokenData } from 'state/info/types'
import { NextLinkFromReactRouter } from 'components/NextLink'
import { CurrencyLogo } from 'views/Info/components/CurrencyLogo'
import { formatAmount } from 'utils/formatInfoNumbers'
import Percent from 'views/Info/components/Percent'
import { useTranslation } from 'contexts/Localization'
import orderBy from 'lodash/orderBy'
import { ClickableColumnHeader, TableWrapper, PageButtons, Arrow, Break } from './shared'
import styles from './Tables.module.scss'
import Link from 'next/link'

/**
 *  Columns on different layouts
 *  6 = | # | Name | Price | Price Change | Volume 24H | TVL |
 *  5 = | # | Name | Price |              | Volume 24H | TVL |
 *  4 = | # | Name | Price |              | Volume 24H |     |
 *  2 = |   | Name |       |              | Volume 24H |     |
 *  On smallest screen Name is reduced to just symbol
 */
const ResponsiveGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;

  padding: 0 2.4rem;

  grid-template-columns: 2rem 3fr repeat(4, 1fr);

  @media screen and (max-width: 90rem) {
    grid-template-columns: 2rem 2fr repeat(3, 1fr);
    & :nth-child(4) {
      display: none;
    }
  }

  @media screen and (max-width: 80rem) {
    grid-template-columns: 2rem 2fr repeat(2, 1fr);
    & :nth-child(6) {
      display: none;
    }
  }

  @media screen and (max-width: 67rem) {
    grid-template-columns: 1fr 1fr;
    > *:first-child {
      display: none;
    }
    > *:nth-child(3) {
      display: none;
    }
  }
`

const ResponsiveLogo = styled(CurrencyLogo)`
  @media screen and (max-width: 67rem) {
    width: 3rem;
    height: 3rem;
  }
`

const TableLoader: React.FC = () => {
  const loadingRow = (
    <ResponsiveGrid>
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </ResponsiveGrid>
  )
  return (
    <>
      {loadingRow}
      {loadingRow}
      {loadingRow}
    </>
  )
}

const DataRow: React.FC<{ tokenData: TokenData; index: number }> = ({ tokenData, index }) => {
  const { isXs, isSm } = useMatchBreakpoints()
  return (
    <div className={styles.tokens_tab}>
      <div className={styles.text}>
        <h6>{index + 1}</h6>
      </div>
      <div>
        <Link href={`/info/token/${tokenData.address}`}>
          <a className={styles.smallRow}>
            <div className={styles.iconTable}>
              <ResponsiveLogo address={tokenData.address} size={'3rem'} />
            </div>
            {(isXs || isSm) && <h5>{tokenData.symbol}</h5>}
            {!isXs && !isSm && (
              <h5>
                {tokenData.name} - {tokenData.symbol}
              </h5>
            )}
          </a>
        </Link>
      </div>
      <div className={styles.text}>
        <h6>${formatAmount(tokenData.priceUSD, { notation: 'standard' })}</h6>
      </div>
      <div className={styles.text}>
        <Percent value={tokenData.priceUSDChange} fontWeight={400} />
      </div>
      <div className={styles.text}>
        <h6>${formatAmount(tokenData.volumeUSD)}</h6>
      </div>
      <div className={styles.text}>
        <h6>${formatAmount(tokenData.liquidityUSD)}</h6>
      </div>
    </div>
  )
}

const SORT_FIELD = {
  name: 'name',
  volumeUSD: 'volumeUSD',
  liquidityUSD: 'liquidityUSD',
  priceUSD: 'priceUSD',
  priceUSDChange: 'priceUSDChange',
  priceUSDChangeWeek: 'priceUSDChangeWeek',
}

const MAX_ITEMS = 10

const TokenTable: React.FC<{
  tokenDatas: TokenData[] | undefined
  maxItems?: number
}> = ({ tokenDatas, maxItems = MAX_ITEMS }) => {
  const [sortField, setSortField] = useState(SORT_FIELD.volumeUSD)
  const [sortDirection, setSortDirection] = useState<boolean>(true)

  const { t } = useTranslation()

  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  useEffect(() => {
    let extraPages = 1
    if (tokenDatas) {
      if (tokenDatas.length % maxItems === 0) {
        extraPages = 0
      }
      setMaxPage(Math.floor(tokenDatas.length / maxItems) + extraPages)
    }
  }, [maxItems, tokenDatas])

  const sortedTokens = useMemo(() => {
    return tokenDatas
      ? orderBy(
          tokenDatas,
          (tokenData) => tokenData[sortField as keyof TokenData],
          sortDirection ? 'desc' : 'asc',
        ).slice(maxItems * (page - 1), page * maxItems)
      : []
  }, [tokenDatas, maxItems, page, sortDirection, sortField])

  const handleSort = useCallback(
    (newField: string) => {
      setSortField(newField)
      setSortDirection(sortField !== newField ? true : !sortDirection)
    },
    [sortDirection, sortField],
  )

  const arrow = useCallback(
    (field: string) => {
      const directionArrow = !sortDirection ? '↑' : '↓'
      return sortField === field ? directionArrow : ''
    },
    [sortDirection, sortField],
  )

  if (!tokenDatas) {
    return <Skeleton />
  }

  return (
    <>
      <div className={styles.pools_wrapper}>
        <div className={styles.pools_container}>
          <div className={styles.pools_row}>
            <div className={styles.tokens_tab}>
              <div className={styles.text}>
                <h6>#</h6>
              </div>
              <div className={styles.text}>
                <h6 className={styles.clickableText} onClick={() => handleSort(SORT_FIELD.name)}>
                  {t('Name')} {arrow(SORT_FIELD.name)}
                </h6>
              </div>
              <div className={styles.text}>
                <h6 className={styles.clickableText} onClick={() => handleSort(SORT_FIELD.priceUSD)}>
                  {t('PriceUSD')} {arrow(SORT_FIELD.priceUSD)}
                </h6>
              </div>
              <div className={styles.text}>
                <h6 className={styles.clickableText} onClick={() => handleSort(SORT_FIELD.priceUSDChange)}>
                  {t('Price Change')} {arrow(SORT_FIELD.priceUSDChange)}
                </h6>
              </div>
              <div className={styles.text}>
                <h6 className={styles.clickableText} onClick={() => handleSort(SORT_FIELD.volumeUSD)}>
                  {t('Volume USD')} {arrow(SORT_FIELD.volumeUSD)}
                </h6>
              </div>
              <div className={styles.text}>
                <h6 className={styles.clickableText} onClick={() => handleSort(SORT_FIELD.liquidityUSD)}>
                  {t('Liquidity')} {arrow(SORT_FIELD.liquidityUSD)}
                </h6>
              </div>
            </div>
          </div>

          {sortedTokens.length > 0 ? (
            <>
              {sortedTokens.map((data, i) => {
                if (data) {
                  return (
                    <div className={styles.pools_row} key={data.address}>
                      <DataRow index={(page - 1) * MAX_ITEMS + i} tokenData={data} />
                    </div>
                  )
                }
                return null
              })}
              <PageButtons>
                <Arrow
                  onClick={() => {
                    setPage(page === 1 ? page : page - 1)
                  }}
                >
                  <ArrowBackIcon color={page === 1 ? 'textDisabled' : 'primary'} />
                </Arrow>
                <Text>{t('Page %page% of %maxPage%', { page, maxPage })}</Text>
                <Arrow
                  onClick={() => {
                    setPage(page === maxPage ? page : page + 1)
                  }}
                >
                  <ArrowForwardIcon color={page === maxPage ? 'textDisabled' : 'primary'} />
                </Arrow>
              </PageButtons>
            </>
          ) : (
            <>
              <TableLoader />
              <Box />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default TokenTable
