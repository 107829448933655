import { NextLinkFromReactRouter } from '@/components/NextLink';
import { overviewDetails } from '@/mock';
import Image from 'next/image';
import styles from './OverviewDetails.module.scss';

const quickLinks: { title: string; subTitle: string; href: string }[] = [
	{ title: 'Swap Tokens', subTitle: "Launch DeSwap", href: "/swap" },
	{ title: 'Add Liquidity', subTitle: "Launch Pools", href: "/addLiquidity" },
	// { title: 'Farm', subTitle: "Launch Farm", href: "/farms" },
]

const OverviewDetails = () => {
	return (
		<div className={styles.overviewDetails}>
			{quickLinks.map(({ title, subTitle, href }, index) => {
				return (
					<div className={styles.overviewDetails_block} key={index}>
						<h3>{title}</h3>
						<NextLinkFromReactRouter to={href}>
							<div className={styles.link_wrapper}>
								<p className={styles.link_text}>{subTitle}</p>
								<div className={styles.link_icon}>
									<Image src="/svgs/arrow-right.svg" layout="fill" alt="" />
								</div>
							</div>
						</NextLinkFromReactRouter>
					</div>
				);
			})}
		</div>
	);
};

export default OverviewDetails;
