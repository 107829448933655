import { logos } from '@/mock'
import { formatAmount, formatAmountNotation } from 'utils/formatInfoNumbers'
import Image from 'next/image'
import styles from './ChartDetails.module.scss'

const formatOptions = {
  notation: 'standard' as formatAmountNotation,
  precision: 6,
  displayThreshold: 0.001,
  tokenPrecision: true,
}

interface TokenDisplayProps {
  value?: number | string
  inputSymbol?: string
  outputSymbol?: string
  isChangePositive?: boolean
  changeValue?: number
  changePercentage?: string | number
}

const ChartDetails = ({
  value,
  inputSymbol,
  outputSymbol,
  isChangePositive,
  changeValue,
  changePercentage,
}: TokenDisplayProps) => {
  return (
    <div className={styles.chartDetails}>
      <div className={styles.chartDetails_block}>
        <p>$DES Price</p>
        <h2>{formatAmount(typeof value === 'string' ? parseFloat(value) : value, formatOptions)}</h2>
      </div>
      <div className={styles.chartDetails_percent}>
        <p className={isChangePositive ? styles.color_green : styles.color_red}>
          {isChangePositive ? '+' : ''}
          {changeValue.toFixed(3)} ({changePercentage}%){' '}
        </p>
        {/* <div>
					<Image src={isChangePositive ? "/svgs/drop-down-green.svg" : "/svgs/drop-down-red.svg"} alt="" layout="fill" />
				</div> */}
      </div>
      <div className={styles.chartDetails_imgContainer}>
        {logos.map(({ title, src, url }, index) => {
          return (
            <a href={url} target="_blank" key={index}>
              <div>
                <Image src={src} alt="" layout="fill" title={title} />
              </div>
            </a>
          )
        })}
      </div>
    </div>
  )
}

export default ChartDetails
